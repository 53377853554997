<template>
  <div
    ref="el"
    class="px-sm sm:px-md md:px-[120px] pt-[53px] pb-xl bg-white md:rounded-minilu-xl"
    :class="{
      '!pt-0': dialogStore.headline && isInDialog,
    }"
  >
    <Form
      id="sendForgotPasswordForm"
      headline="forgotPassword.headline"
      submit-label="forgotPassword.submit"
      info="forgotPassword.info"
      :is-loading="isLoading"
      @submit="onSubmit"
    >
      <template #errors>
        <div v-if="hasError">
          <UnknownError />
        </div>
      </template>
      <template #default>
        <TextInput
          name="email"
          label="login.emailLabel"
          placeholder="login.emailPlaceholder"
          required-message="login.emailRequired"
          autofocus
        />
      </template>
    </Form>
  </div>
</template>
<script setup lang="ts">
import Form from '~/components/formFields/form/form.vue';
import { TextInput } from '@/complib';
import UnknownError from '@/components/formFields/errors/unknown/unknownError.vue';
import { useSendForgotPasswordForm } from '../useSendForgotPasswordForm';
import { useDialogStore } from '@/stores/useDialogStore';

const emit = defineEmits<{
  (e: 'success', email: string): void;
}>();
const { isLoading, hasError, showSuccessView, onSubmit, email } =
  useSendForgotPasswordForm();
const dialogStore = useDialogStore();
const el = ref<HTMLElement | null>(null);

const isInDialog = useIsInDialog(el);
watch(showSuccessView, (val) => {
  if (val) emit('success', email.value);
});
</script>
